import { cn } from "@/utilities";
import * as React from "react";

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  prefix?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, prefix, ...props }, ref) => {

    // Función para manejar el cambio de valor con validaciones
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;

      // Evitar que el valor inicie con un 0
      if (value.startsWith("0") && value.length > 1) {
        value = value.slice(1);
      }

      // Evitar valores negativos
      if (parseFloat(value) < 0) {
        value = "0";
      }

      // Actualizar el valor del input
      e.target.value = value;
      if (props.onChange) {
        props.onChange(e);
      }
    };

    return (
      <div className="flex items-center w-full rounded-md border border-input bg-transparent focus-within:ring-1 focus-within:ring-ring">
        {prefix && (
          <div className="flex items-center pl-3">
            {prefix}
          </div>
        )}
        <input
          type={type}
          className={cn(
            "flex h-8 w-full rounded-md bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
            prefix && "pl-1", // Agregar padding si existe un prefijo
            className
          )}
          ref={ref}
          {...props}
          onChange={handleChange} // Aplicar la función personalizada de cambio
        />
      </div>
    );
  }
);

Input.displayName = "Input";

export { Input };
